import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
}

const OrderSupportSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5032 18.3833C12.454 18.3832 12.4054 18.3724 12.3608 18.3517L6.32915 15.4506C6.27406 15.4232 6.22763 15.3812 6.19504 15.329C6.16244 15.2769 6.14496 15.2167 6.14453 15.1552V8.66201C6.14436 8.60654 6.15831 8.55194 6.18505 8.50335C6.21179 8.45475 6.25045 8.41376 6.2974 8.38422C6.34435 8.35468 6.39804 8.33757 6.45342 8.33449C6.5088 8.33141 6.56405 8.34247 6.61398 8.36662L12.6456 11.2677C12.7007 11.2951 12.7472 11.3371 12.7797 11.3893C12.8123 11.4414 12.8298 11.5016 12.8302 11.5631V18.0563C12.8303 18.1116 12.8162 18.1661 12.7895 18.2145C12.7627 18.2629 12.7241 18.3038 12.6773 18.3332C12.6253 18.3664 12.5649 18.3838 12.5032 18.3833ZM6.7986 14.9548L12.1762 17.5446V11.7715L6.7986 9.18157V14.9548Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M12.502 11.8873C12.4564 11.8905 12.4106 11.8843 12.3675 11.8688L6.33587 8.96773C6.27987 8.94165 6.23249 8.90012 6.19928 8.84803C6.16608 8.79593 6.14844 8.73544 6.14844 8.67367C6.14844 8.61189 6.16608 8.5514 6.19928 8.49931C6.23249 8.44721 6.27987 8.40568 6.33587 8.3796L12.3675 5.46531C12.4116 5.44443 12.4598 5.43359 12.5086 5.43359C12.5574 5.43359 12.6056 5.44443 12.6497 5.46531L18.6814 8.36641C18.7368 8.39325 18.7836 8.43522 18.8163 8.48748C18.8489 8.53974 18.8662 8.60017 18.866 8.6618C18.866 8.72305 18.8486 8.78304 18.816 8.83485C18.7833 8.88666 18.7366 8.92815 18.6814 8.95455L12.6313 11.8688C12.5899 11.8836 12.5459 11.8899 12.502 11.8873ZM7.2273 8.65916L12.502 11.1989L17.7768 8.66444L12.502 6.12202L7.2273 8.65916Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M12.5028 18.3826C12.4412 18.3831 12.3807 18.3657 12.3287 18.3325C12.2819 18.3031 12.2433 18.2622 12.2166 18.2138C12.1898 18.1654 12.1758 18.1109 12.1758 18.0556V11.5651C12.1756 11.5034 12.1928 11.443 12.2255 11.3907C12.2582 11.3385 12.3049 11.2965 12.3604 11.2697L18.392 8.36858C18.442 8.34442 18.4972 8.33336 18.5526 8.33644C18.608 8.33952 18.6617 8.35663 18.7086 8.38617C18.7556 8.41571 18.7942 8.45671 18.821 8.5053C18.8477 8.5539 18.8617 8.60849 18.8615 8.66396V15.1519C18.8617 15.2135 18.8445 15.2739 18.8118 15.3262C18.7791 15.3785 18.7324 15.4204 18.6769 15.4473L12.632 18.351C12.5916 18.3703 12.5476 18.3811 12.5028 18.3826ZM12.8298 11.7708V17.536L18.2074 14.9462V9.18089L12.8298 11.7708Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M9.48387 10.4355C9.41044 10.4348 9.33939 10.4093 9.28218 10.3633C9.22496 10.3172 9.18491 10.2533 9.16846 10.1817C9.15202 10.1102 9.16014 10.0351 9.19152 9.96874C9.2229 9.90235 9.27571 9.84845 9.34145 9.81572L15.3757 6.91462C15.4538 6.87744 15.5433 6.87266 15.6249 6.90132C15.7064 6.92998 15.7733 6.98976 15.8109 7.06759C15.8297 7.10617 15.8406 7.14808 15.8431 7.19091C15.8455 7.23374 15.8395 7.27663 15.8252 7.3171C15.811 7.35757 15.7889 7.39482 15.7602 7.42669C15.7315 7.45855 15.6967 7.48441 15.6579 7.50276L9.62629 10.4039C9.58158 10.4243 9.53304 10.4351 9.48387 10.4355Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M10.2958 13.2841C10.2466 13.2843 10.1979 13.2735 10.1534 13.2525L8.37316 12.3953C8.33439 12.377 8.29963 12.3511 8.27091 12.3193C8.2422 12.2874 8.22008 12.2501 8.20586 12.2097C8.19163 12.1692 8.18557 12.1263 8.18803 12.0835C8.1905 12.0407 8.20143 11.9987 8.2202 11.9602C8.25779 11.8823 8.32467 11.8226 8.40622 11.7939C8.48776 11.7652 8.57734 11.77 8.65536 11.8072L10.4356 12.6591C10.5011 12.6917 10.5539 12.7454 10.5853 12.8115C10.6167 12.8776 10.625 12.9524 10.6089 13.0238C10.5928 13.0952 10.5532 13.1592 10.4964 13.2054C10.4397 13.2517 10.369 13.2775 10.2958 13.2788V13.2841Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M2.59417 16.2811C2.53034 16.2821 2.46761 16.2644 2.41371 16.2302C2.35982 16.196 2.31712 16.1467 2.29087 16.0885C1.30096 13.6405 1.22424 10.9181 2.07472 8.41813C2.9252 5.91819 4.64618 3.80738 6.92361 2.47085C9.20104 1.13433 11.8831 0.661197 14.4804 1.1378C17.0777 1.6144 19.417 3.00896 21.0715 5.067C21.1022 5.09975 21.1258 5.13848 21.1409 5.18076C21.156 5.22304 21.1622 5.26796 21.1591 5.31274C21.1561 5.35752 21.1438 5.4012 21.1232 5.44105C21.1025 5.4809 21.0739 5.51607 21.0391 5.54437C21.0042 5.57267 20.9639 5.5935 20.9207 5.60556C20.8775 5.61762 20.8322 5.62065 20.7878 5.61447C20.7433 5.60828 20.7006 5.59301 20.6623 5.56961C20.624 5.5462 20.5909 5.51516 20.5652 5.47843C19.0087 3.54847 16.8113 2.24123 14.3726 1.79447C11.9338 1.34771 9.4157 1.7911 7.27622 3.044C5.13674 4.29691 3.518 6.27609 2.71442 8.6216C1.91084 10.9671 1.97579 13.5231 2.89746 15.8248C2.91727 15.8744 2.92464 15.9281 2.91892 15.9812C2.91319 16.0342 2.89456 16.0851 2.86464 16.1294C2.83472 16.1736 2.79443 16.2098 2.74728 16.2349C2.70013 16.2599 2.64756 16.2731 2.59417 16.2732V16.2811Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M12.5174 22.9684C10.8707 22.9724 9.2441 22.6063 7.7578 21.8974C6.2715 21.1885 4.96341 20.1548 3.93012 18.8726C3.87556 18.8027 3.85102 18.7139 3.8619 18.6259C3.87278 18.5378 3.91819 18.4577 3.98814 18.4032C4.05809 18.3486 4.14684 18.3241 4.23488 18.335C4.32292 18.3458 4.40303 18.3912 4.45759 18.4612C6.02351 20.3889 8.23029 21.6893 10.6753 22.1253C13.1203 22.5612 15.6405 22.1036 17.7762 20.8359C19.9118 19.5682 21.5205 17.5749 22.3088 15.2197C23.097 12.8646 23.0122 10.3045 22.0699 8.00669C22.0522 7.96682 22.0426 7.9238 22.0418 7.88018C22.041 7.83656 22.0489 7.79321 22.065 7.75269C22.0812 7.71216 22.1053 7.67529 22.136 7.64423C22.1666 7.61317 22.2031 7.58855 22.2434 7.57184C22.2837 7.55512 22.327 7.54663 22.3706 7.54688C22.4142 7.54713 22.4574 7.5561 22.4975 7.57328C22.5376 7.59045 22.5739 7.61548 22.6042 7.64689C22.6344 7.67829 22.6581 7.71544 22.6739 7.75614C23.5724 9.95534 23.7401 12.3852 23.1522 14.6869C22.5643 16.9887 21.2519 19.0405 19.4088 20.5394C17.4575 22.1143 15.0249 22.9717 12.5174 22.9684Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M20.8456 5.62234H20.8087L17.7625 5.29267C17.6807 5.27827 17.6075 5.23328 17.5576 5.16684C17.5078 5.10039 17.4851 5.01747 17.4941 4.93491C17.5032 4.85234 17.5433 4.77632 17.6064 4.72227C17.6695 4.66822 17.7508 4.64019 17.8337 4.64388L20.5449 4.93663L20.8482 2.21487C20.8502 2.17018 20.8614 2.12637 20.8809 2.08615C20.9005 2.04594 20.9282 2.01017 20.9621 1.98106C20.9961 1.95195 21.0357 1.93012 21.0784 1.91691C21.1212 1.90371 21.1662 1.89942 21.2106 1.9043C21.2551 1.90918 21.2981 1.92313 21.3369 1.94529C21.3758 1.96745 21.4097 1.99734 21.4366 2.03313C21.4634 2.06891 21.4826 2.10982 21.493 2.15333C21.5034 2.19684 21.5048 2.24202 21.497 2.28608L21.17 5.33223C21.1602 5.4184 21.1165 5.49714 21.0487 5.55113C20.9913 5.59766 20.9195 5.62283 20.8456 5.62234Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
      <path
        d="M3.83657 22.015H3.79965C3.7137 22.0057 3.63494 21.9627 3.58057 21.8955C3.52621 21.8283 3.50067 21.7423 3.50954 21.6563L3.83921 18.6102C3.84331 18.5676 3.85593 18.5263 3.87632 18.4886C3.89671 18.451 3.92445 18.4179 3.95789 18.3913C4.02542 18.3369 4.11164 18.3113 4.19789 18.32L7.24404 18.6471C7.32546 18.6616 7.39835 18.7064 7.44803 18.7726C7.49772 18.8387 7.52051 18.9212 7.51182 19.0034C7.50312 19.0856 7.46359 19.1615 7.40119 19.2158C7.33878 19.2701 7.25813 19.2987 7.17547 19.2959L4.45635 19.0031L4.15833 21.7249C4.14988 21.8044 4.11236 21.878 4.05298 21.9315C3.9936 21.9851 3.91653 22.0148 3.83657 22.015Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.6"
      />
    </svg>
  );
};

export default OrderSupportSvg;
