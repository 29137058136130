import React, {useState} from "react";
import "./LiveCard.css";
import {CometSvg} from "../SvgImages/CometSvg/CometSvg";
import {useSelector} from "react-redux";
import {RootState} from "../../interceptors/store";
import {useNavigate} from "react-router";
// import {Hashtag} from "../SvgImages/Hashtag/Hashtag";
import moment from "moment";
import {RecommendProductSkeleton} from "../RecommendedProduct/RecommendProductSkeleton";
import HLSPlayer from "../HslPlayer/HslPlayer";

const hlsContainerStyle: React.CSSProperties = {
  marginTop: 0,
  padding: 0,
  aspectRatio: 9 / 16,
};
const hlsVideoStyle: React.CSSProperties = {};

export const LiveCardComponent = () => {
  const {streams} = useSelector((state: RootState) => state.liveStream);

  return (
    <>
      <div className="live-card-container">
        <span className="comet-svg" style={{display: "none"}}>
          <CometSvg height={100} width={100} />
        </span>
        <div className="live-card-header">
          {/* <span id="recommended-hastag">
            <Hashtag height={50} width={50} />
          </span> */}
          <span style={{color: "#9ca3af"}}> Zoop</span>
          <span style={{marginLeft: "4px"}}>LIVE</span>
        </div>

        <div className="live-card-content">
          {streams.length == 0
            ? Array(4)
                .fill(0)
                .map((_, index) => (
                  <div className="recommended-product" key={index}>
                    <RecommendProductSkeleton />
                  </div>
                ))
            : streams.map((item, index) => (
                <div className="live-cards" key={index}>
                  <StreamCard
                    image={item?.thumbnail}
                    streamName={item?.title}
                    sellerName={item?.seller_store_name || ""}
                    streamStatus={item?.status}
                    time={item?.scheduled_time}
                    streamId={item.id?.toString()}
                    videoUrl={item?.preview_url}
                  />
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

interface ProductCardProps {
  image: string; // URL of the live cover image
  streamName: string;
  sellerName: string;
  streamStatus: string;
  time: string;
  streamId: string;
  videoUrl: string;
}

const StreamCard: React.FC<ProductCardProps> = ({
  image,
  streamName,
  sellerName,
  streamStatus,
  time,
  streamId,
  videoUrl,
}) => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  const handlePlayClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent navigation
    setIsPlaying(true);
  };

  const handleCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent navigation
    setIsPlaying(false);
  };

  let timeLeft = "";

  const currentTime = moment();
  const scheduledTime = moment(time);
  const duration = moment.duration(scheduledTime.diff(currentTime));

  if (
    duration.asSeconds() > 0 &&
    (streamStatus === "open" || streamStatus === "url_generated")
  ) {
    const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
    const minutes = Math.floor(duration.minutes()).toString().padStart(2, "0");
    const seconds = Math.floor(duration.seconds()).toString().padStart(2, "0");

    timeLeft = `${hours}:${minutes}:${seconds}`;
  } else {
    timeLeft = "Live Now";
  }

  const status =
    timeLeft === "Live Now"
      ? streamStatus === "in_progress" || streamStatus === "url_generated"
        ? "Live"
        : "Stream Ended"
      : "Upcoming";

  return (
    <div
      className="stream-live-card"
      onClick={() => {
        navigate(`/stream/${streamId}`);
      }}>
      <div className="stream-live-card-image">
        {!isPlaying ? (
          <img src={image} alt={streamName} className="preview-image" />
        ) : (
          <div className="live-card-preview-video">
            {videoUrl == null ? (
              <div className="preview-image">
                <p className="live-card-preview-no-preview">
                  Sorry! No video preview available
                </p>
              </div>
            ) : (
              <HLSPlayer
                hlsContainerStyle={hlsContainerStyle}
                hlsVideoStyle={hlsVideoStyle}
                isMuted={true}
                data={{
                  public_url: `${videoUrl}`,
                }}
                onVideoEnd={handleVideoEnd}
              />
            )}
          </div>
        )}
        {isPlaying && (
          <button
            className="live-card-preview-close-button"
            onClick={handleCloseClick}>
            ✕
          </button>
        )}
        {!isPlaying && status === "Stream Ended" && videoUrl !== null && (
          <button
            className="live-card-preview-play-button"
            onClick={handlePlayClick}>
            ▶
          </button>
        )}
        <div
          className={`stream-card-live-badge `}
          style={{
            background:
              status === "Live"
                ? "#EF4444"
                : status === "Upcoming"
                  ? "#3897f1"
                  : "#111827",
          }}>
          {status}
        </div>
      </div>
      <div className="stream-live-card-content">
        <h3 className="stream-live-name">{streamName}</h3>
        <p className="stream-live-seller-name">{sellerName}</p>
      </div>
    </div>
  );
};
