import React from "react";
import "./ReviewCard.css";

interface ReviewCardProps {
  name: string;
  date: string;
  description: string;
  image: string | null;
  reviewImages?: string[];
  averageRating?: number;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  name,
  date,
  description,
  image,
}) => {
  return (
    <div className="review-card-review-item">
      <div className="review-card-review-header">
        <img
          src={
            image ??
            "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/sellerprofile.png"
          }
          alt="User Avatar"
          className="review-card-user-avatar"
        />
        <div className="review-card-review-meta">
          <h3 className="review-card-user-name">{name}</h3>
          <p className="review-card-review-date">{date}</p>
        </div>
      </div>
      <p className="review-card-review-text">{description}</p>
    </div>
  );
};

export default ReviewCard;
