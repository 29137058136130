import React, {ChangeEvent, useState, SetStateAction, Dispatch} from "react";
import "./RemindMePopup.css";
import {PopupBellIcon} from "../SvgImages/PopBellIcon/PopBellIcon";
import {IndianFlagIcon} from "../SvgImages/IndianFlag/IndianFlag";
import WhatsAppIcon from "../SvgImages/WhatsappIcon/WhatsAppIcon";
import Loader from "../CustomLoader/CustomLoader";
import {toast} from "react-toastify";

interface RemindMePopupProps {
  onSubmit: (phoneNumber: string) => void;
  onCancel: () => void;
  onOkay: () => void;
  isOpen: boolean;
  setLoader: Dispatch<SetStateAction<boolean>>;
  loader: boolean;
}

const RemindMePopup: React.FC<RemindMePopupProps> = ({
  onSubmit,
  isOpen,
  onCancel,
  loader,
  onOkay,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [reminderSent, setReminderSent] = useState<boolean>(false);
  // const [isTransitioning, setIsTransitioning] = useState<boolean>(false); // State to control transition

  const handleSubmit = async () => {
    const phoneRegex = /^[789]\d{9}$/;
    if (phoneNumber && phoneRegex.test(phoneNumber)) {
      onSubmit(phoneNumber);
      setReminderSent(true);

      // Start the sliding transition
      // setIsTransitioning(true);

      // Wait for the slide effect to complete before fully switching the content
      // setTimeout(() => {
      //   // setIsTransitioning(false); // After transition, remove the sliding effect
      // }, 300); // Duration should match the CSS transition time (0.3s)
    } else {
      setReminderSent(false);
      toast.error("Please enter a valid phone number.");
    }
  };

  const preventWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).blur();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      return;
    }
  };

  const handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="remind-me-popup-overlay">
      <div className={`remind-me-popup`}>
        <div className="popup-header">
          <PopupBellIcon width={50} height={50} />
          <p>{reminderSent ? "Thank you! Stay tuned" : "Remind me!!"}</p>
        </div>

        {reminderSent ? (
          <>
            <div className="popup-whatsapp-text-container">
              <WhatsAppIcon width={36} height={30} />
              <p className="popup-whatsapp-text">
                {`We'll notify you at `}
                <span style={{color: "black"}}>
                  {`${phoneNumber ? "+91 " + phoneNumber : "your provided mobile number,"}`}
                </span>
                {` when the live stream starts`}
              </p>
            </div>
            <div className="popup-footer">
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#1E799C",
                  color: "white",
                }}
                className="popup-cancel-button"
                onClick={onOkay}>
                Okay
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="popup-body">
              <div className="phone-input">
                <span style={{margin: "3px"}}>
                  <IndianFlagIcon width={35} height={30} />
                </span>
                <select className="country-code">
                  <option value="+91">+91</option>
                </select>
                <input
                  type="number"
                  className="phone-number"
                  value={phoneNumber}
                  placeholder="Enter number"
                  onWheel={preventWheel}
                  onKeyDown={handleKeyDown}
                  maxLength={10}
                  onChange={handleMobileNumberChange}
                />
              </div>
              <div className="popup-whatsapp-text-container">
                <WhatsAppIcon width={36} height={30} />
                <p className="popup-whatsapp-text">
                  {`We'll send you a message on WhatsApp to ensure you don't miss this live show!`}
                </p>
              </div>
            </div>
            <div className="popup-footer">
              <button className="popup-cancel-button" onClick={onCancel}>
                Cancel
              </button>
              <button
                style={{
                  backgroundColor:
                    phoneNumber.length !== 10 ? "#EDEEF2" : "#1E799C",
                  color: phoneNumber.length !== 10 ? "grey" : "white",
                }}
                disabled={phoneNumber.length !== 10}
                className="popup-submit-button"
                onClick={handleSubmit}>
                {loader ? <Loader size={15} /> : "Submit"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RemindMePopup;
