import React from "react";

export interface BuyerProtectionProps {
  height: number | string;
  width: number | string;
}

export const BuyerProtectionSvg = function BuyerProtectionSvg(
  props: BuyerProtectionProps,
) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.79515 10.9829C12.6246 10.9829 14.9184 8.68923 14.9184 5.8596C14.9184 3.03013 12.6246 0.736328 9.79515 0.736328C6.96567 0.736328 4.67188 3.03013 4.67188 5.8596C4.6746 8.68817 6.96673 10.9801 9.79515 10.9829ZM9.79515 1.7057C12.0894 1.7057 13.949 3.56565 13.949 5.8596C13.949 8.15385 12.0894 10.0135 9.79515 10.0135C7.50105 10.0135 5.6414 8.15385 5.6414 5.8596C5.64398 3.56656 7.50211 1.70843 9.79515 1.7057Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.5"
      />
      <path
        d="M23.1107 13.8487L18.9325 11.624C18.7886 11.5455 18.6143 11.5474 18.472 11.6287L14.6477 13.7227C13.2469 12.6805 11.5697 12.1377 9.78628 12.1377C7.56629 12.1426 5.50631 13.0054 3.97943 14.5758C2.43316 16.1607 1.58487 18.3274 1.58987 20.6781C1.59062 20.9456 1.80717 21.1622 2.07448 21.1629L15.5589 21.1387C16.1928 21.9257 17.0077 22.5476 17.9339 22.9515L18.4963 23.1986C18.5573 23.2259 18.6236 23.2391 18.6902 23.2374C18.7566 23.2362 18.8224 23.2232 18.884 23.1986L19.519 22.9224C21.8519 21.9436 23.3692 19.6601 23.3675 17.1301V14.28C23.3677 14.1 23.269 13.9343 23.1107 13.8487ZM2.57379 20.1887C2.67547 18.2837 3.41708 16.5438 4.67741 15.2495C6.01986 13.8681 7.83753 13.1071 9.79098 13.1071H9.80553C11.41 13.1071 12.9126 13.6113 14.1582 14.5661V17.1883C14.159 18.2293 14.4187 19.2537 14.9143 20.1691L2.57379 20.1887ZM22.3982 17.1301C22.3991 19.2722 21.1125 21.2049 19.136 22.0306H19.1312L18.6902 22.2196L18.3217 22.0595C16.3832 21.2126 15.1297 19.299 15.1276 17.1836V14.5613L18.7095 12.5983L22.3982 14.5661V17.1301Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.5"
      />
      <path
        d="M17.3803 16.8732C17.2064 16.6699 16.9004 16.6461 16.6969 16.82C16.4935 16.994 16.4698 17.2999 16.6437 17.5033L17.7439 18.7877C17.8363 18.8952 17.9707 18.957 18.1122 18.9574C18.2233 18.9584 18.3312 18.9208 18.4176 18.8508L20.9769 16.7715C21.1843 16.6015 21.2146 16.2956 21.0446 16.088C20.8747 15.8805 20.5688 15.8502 20.3613 16.0202L18.1704 17.804L17.3803 16.8732Z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth="0.5"
      />
    </svg>
  );
};
