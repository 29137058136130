import React, {useEffect, useState} from "react";
import {getSearchProduct} from "../../../features/Inventory/InventoryApi";
import "./SearchPage.css";
import {GetSearchProductsResponse} from "../../../features/Inventory/InventoryInterface";
import {useNavigate} from "react-router";
import SearchPageSkeletonLoader from "./SearchPageSkeleton";

export const Searchpage: React.FC = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<GetSearchProductsResponse>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  // const [productCount, setProductCount] = useState<number>(0);
  // const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  // const limit = 10;
  // const totalPages = Math.ceil(productCount / limit);

  const urlParams = new URLSearchParams(window.location.search);
  const queryParam = urlParams.get("query") ?? "";

  useEffect(() => {
    getSearchProducts();
  }, [window.location.search]);

  const getSearchProducts = async () => {
    setLoadingData(true);

    try {
      const productData = await getSearchProduct({
        page: 1,
        limit: 10,
        query: queryParam,
      });
      setProducts(productData);
      // setProductCount(productData?.data?.products?.net_count);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(true);
      console.log("ERROR :::", error);
    }
  };

  const noProductsFound =
    !loadingData && products?.data?.products?.product_data.length === 0;

  return (
    <>
      {loadingData ? (
        <SearchPageSkeletonLoader />
      ) : noProductsFound ? (
        <div className="no-products-found">
          <h2>No products found</h2>
          <p>
            Your search did not match any products. Please try again with
            different keywords.
          </p>
        </div>
      ) : (
        <div className="search-products-product-section-product-grid">
          {/* Render each product */}
          {products?.data?.products?.product_data.map((product, index) => {
            const product_image = product
              .productImageDetails[0] as unknown as string;
            const isSoldOut = product.product_total_quantity === 0;
            const isDiscounted =
              parseInt(product.min_product_price) -
                parseInt(product.min_selling_price) >
              0;
            const discountPercentage = isDiscounted
              ? (
                  ((parseInt(product.min_product_price) -
                    parseInt(product.min_selling_price)) /
                    parseInt(product.min_product_price)) *
                  100
                ).toFixed(0)
              : null;

            return (
              <div
                key={index}
                className="search-products-product-section-product-card"
                onClick={() => {
                  navigate(`/product/${product.id}`);
                }}>
                {/* Display Sold Out or Discount tag */}
                {(isDiscounted || isSoldOut) && (
                  <div className="search-products-product-section-tag-container">
                    <div
                      className="search-products-product-section-tag-cover"
                      style={{
                        backgroundColor: isSoldOut ? "#F3F4F6" : "#dc2626",
                      }}>
                      <span
                        className="search-products-product-section-tag-cover-text"
                        style={{
                          color: isSoldOut ? "#374151" : "#fef2f2",
                        }}>
                        {isSoldOut ? "Sold Out" : `${discountPercentage}% off`}
                      </span>
                    </div>
                  </div>
                )}

                {/* Product Image */}
                <div className="search-products-product-section-product-image-box">
                  <img
                    src={
                      product_image ||
                      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                    }
                    alt="Product"
                    className="search-products-product-section-product-image"
                  />
                </div>

                {/* Product Info */}
                <div className="search-products-product-section-info">
                  <div className="search-products-product-section-name">
                    {product.product_name}
                  </div>
                  <div className="search-products-product-section-description text-truncate2">
                    {product.product_description}
                  </div>
                  <div className="search-products-product-section-price">
                    ₹{product.min_selling_price}{" "}
                    {isDiscounted && (
                      <span className="search-products-product-section-original-price">
                        ₹{product.min_product_price}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* 
      {products?.data?.products?.product_data && (
        <div className="storefront-pagination-container">
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev: number) => prev - 1)}>
            &#8249;
          </button>
          <div className="storefront-pagination-numbers">
            Page
            <span
              className={`storefront-pagination-number ${currentPage === 1 ? "active" : ""}`}
              onClick={() => setCurrentPage(1)}>
              1
            </span>
            {currentPage > 3 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {currentPage > 2 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage - 1)}>
                {currentPage - 1}
              </span>
            )}
            {currentPage !== 1 && currentPage !== totalPages && (
              <span className="storefront-pagination-number active">
                {currentPage}
              </span>
            )}
            {currentPage < totalPages - 1 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage + 1)}>
                {currentPage + 1}
              </span>
            )}
            {currentPage < totalPages - 2 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {totalPages > 1 && (
              <span
                className={`storefront-pagination-number ${
                  currentPage === totalPages ? "active" : ""
                }`}
                onClick={() => setCurrentPage(totalPages)}>
                {totalPages}
              </span>
            )}
          </div>
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev: number) => prev + 1)}>
            &#8250;
          </button>
        </div>
      )} */}
    </>
  );
};
