import React from "react";
import "./ImageModal.css";

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
}

const ImageModal: React.FC<ImageModalProps> = ({isOpen, onClose, imageSrc}) => {
  if (!isOpen) return null;

  return (
    <div className="image-modal-overlay">
      <div className="image-modal-container">
        <button onClick={onClose} className="image-modal-close-button">
          &times;
        </button>
        <img src={imageSrc} alt="Product" className="image-modal-image" />
      </div>
    </div>
  );
};

export default ImageModal;
