import React, {useRef, useState} from "react";
import {ToastContainer, toast} from "react-toastify";
import Loader from "../../../../Loader/Loader";
import "./Otp.style.css";
import {useDispatch} from "react-redux";
import useTimer from "../../../../hooks/useTime";
import {
  getSendOtpAuthenticationThunk,
  getVerifyOtpAuthenticationThunk,
} from "../../../../features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {AppDispatch} from "../../../../interceptors/store";

// Define types for props
interface OtpVerificationProps {
  mobileNumber: string;
  timeLeft: number;
  setIsEditing: (isEditing: boolean) => void;
  setUserId: (userId: string) => void;
  setIsOtpSent: (isOtpSent: boolean) => void;
  setIsOtpVerified: (isOtpVerified: boolean) => void;
  setMobileNumber: (mobileNumber: string) => void;
  messageId: string;
  setMessageId: (messageId: string) => void;
}

const OtpVerification: React.FC<OtpVerificationProps> = ({
  mobileNumber,
  setIsEditing,
  setUserId,
  setIsOtpSent,
  setIsOtpVerified,
  setMobileNumber,
  messageId,
  setMessageId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [otp, setOtp] = useState<string>("");

  const otpInputs = useRef<HTMLInputElement[]>([]);

  const {time, resetTimer} = useTimer({
    initialTime: 30,
    interval: 1000,
    onFinish: () => {
      console.log("time Finished");
    },
  });

  const handleVerifyOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User",
          action: "VerifyOtp",
          label: "Verify OTP Attempt",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
          dimension6: otp,
        });

        const action = await dispatch(
          getVerifyOtpAuthenticationThunk({
            country_code: "+91",
            user_type: "buyer",
            mobile: mobileNumber,
            otp: Number(otp),
            message_id: messageId,
          }),
        );

        if (getVerifyOtpAuthenticationThunk.fulfilled.match(action)) {
          toast.success("OTP verified successfully", {
            toastId: messageId,
          });
          const data = action.payload;
          const userId = data?.data?.user_id;
          setUserId(userId);
          setLoading(false);
          setIsOtpVerified(true);
        } else {
          ReactGA.event({
            category: "User",
            action: "VerifyOtpError",
            label: "Verify OTP Error",
            value: 1,
            nonInteraction: true,
            // @ts-expect-error: Ignoring the type check for custom dimensions
            dimension1: location.pathname + location.search,
            dimension2: localStorage.getItem("userId") || null,
            dimension3: mobileNumber,
            dimension4: "+91",
            dimension5: "buyer",
            dimension6: otp,
          });
          toast.error("Error verifying OTP");
          setLoading(false);
        }
      } catch {
        ReactGA.event({
          category: "User",
          action: "InvalidOtpError",
          label: "Invalid OTP Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
          dimension6: otp,
        });
        setLoading(false);
        toast.error("Invalid OTP");
      }
    } else {
      toast.error("Please enter a valid 6-digit OTP.");
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      ReactGA.event({
        category: "User",
        action: "ResendOtp",
        label: "Resend OTP",
        value: 1,
        nonInteraction: true,
        // @ts-expect-error: Ignoring the type check for custom dimensions
        dimension1: location.pathname + location.search,
        dimension2: localStorage.getItem("userId") || null,
        dimension3: mobileNumber,
        dimension4: "+91",
        dimension5: "buyer",
      });

      const action = await dispatch(
        getSendOtpAuthenticationThunk({
          country_code: "+91",
          user_type: "buyer",
          mobile: mobileNumber,
        }),
      );

      if (getSendOtpAuthenticationThunk.fulfilled.match(action)) {
        if (action?.payload?.data?.message_id) {
          setMessageId(action?.payload?.data?.message_id);
          toast.success("OTP resent successfully");
          resetTimer(30);
          setLoading(false);
        } else {
          ReactGA.event({
            category: "User",
            action: "ResendOtpError",
            label: "Resend OTP Error",
            value: 1,
            nonInteraction: true,
            // @ts-expect-error: Ignoring the type check for custom dimensions
            dimension1: location.pathname + location.search,
            dimension2: localStorage.getItem("userId") || null,
            dimension3: mobileNumber,
            dimension4: "+91",
            dimension5: "buyer",
          });
          toast.error("Error resending OTP");
        }
      } else {
        ReactGA.event({
          category: "User",
          action: "ResendOtpError",
          label: "Resend OTP Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || null,
          dimension3: mobileNumber,
          dimension4: "+91",
          dimension5: "buyer",
        });
        toast.error("Error resending OTP");
      }
    } catch {
      setLoading(false);
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    const digits = value.replace(/\D/g, "").split("");

    if (digits.length > 0) {
      const newOtp = [...otp];
      let currentIndex = index;

      digits.forEach(digit => {
        if (currentIndex < 6) {
          newOtp[currentIndex] = digit;
          otpInputs.current[currentIndex].value = digit;
          currentIndex++;
        }
      });

      setOtp(newOtp.join(""));

      if (currentIndex < 6) {
        otpInputs.current[currentIndex].focus();
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div className="otp-joinChatContainer">
          <div className="otp-joinChatHeading">Enter the OTP</div>
          <div className="otp-joinChatSubHeading">
            We’ve sent you the verification code
          </div>
          <div className="otp-joinChatSubHeading">
            +91 {mobileNumber}
            <span
              style={{
                color: "#1E799C",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => {
                setIsEditing(true);
                setMobileNumber(mobileNumber);
                setIsOtpSent(false);
              }}>
              Edit
            </span>
          </div>
          <div className="otpInputContainer" style={{display: "flex"}}>
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                ref={el => {
                  if (el) {
                    otpInputs.current[index] = el; // Directly assign without optional chaining
                  }
                }}
                type="number"
                maxLength={1}
                className="otpInput"
                onChange={e => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>

          <div
            style={{
              // position: "absolute",
              bottom: 0,
              width: "100%",
              marginBottom: "6%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <div
              style={{
                marginBottom: "3%",
              }}>
              {time <= 0 ? (
                <div
                  className="otp-joinChatSubHeading"
                  style={{color: "#1E799C", fontWeight: "bold"}}
                  onClick={handleResendOtp}>
                  Resend OTP
                </div>
              ) : (
                <div className="otp-joinChatSubHeading">
                  Waiting for OTP ({time})
                </div>
              )}
            </div>

            <button
              id="submit-button"
              style={{
                backgroundColor: otp.length === 6 ? "#1E799C" : "#EDEEF2",
                color: otp.length === 6 ? "#FFFFFF" : "#9EA2B3",
              }}
              onClick={handleVerifyOtp}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OtpVerification;
