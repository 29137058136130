/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState, ChangeEvent} from "react";
import {ToastContainer, toast} from "react-toastify";
import Loader from "../../../../Loader/Loader";
import {useNavigate} from "react-router";
import {updateUserData} from "../../../../features/User/UserApi";
import {useSelector} from "react-redux";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import cookieUtils from "../../../../utils/cookies";
import {logoutUser} from "../../../../utils/helpers";
import {RootState} from "../../../../interceptors/store";

import "./UserDetails.style.css";

interface Payload {
  first_name: string;
  last_name: string;
  email: string;
  user_name: string;
}

const UserDetails: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>(
    localStorage.getItem("username") || "",
  );
  const [firstName, setFirstName] = useState<string>(
    localStorage.getItem("firstName") || "",
  );
  const [lastName, setLastName] = useState<string>(
    localStorage.getItem("lastName") || "",
  );
  const [email, setEmail] = useState<string>(
    localStorage.getItem("email") || "",
  );
  const user = useSelector((state: RootState) => state.user.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookieUtils.getCookie("token")) {
      logoutUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setUsername(user.user_name || "");
      setEmail(user.email || "");
    }
  }, [user]);

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const validatePayload = (payload: Payload) => {
    const {first_name, last_name, email, user_name} = payload;

    if (!first_name || !last_name || !email || !user_name) {
      return {isValid: false, message: "All fields are required."};
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return {isValid: false, message: "Invalid email format."};
    }

    const usernameRegex = /^\S{3,}$/;
    if (!usernameRegex.test(user_name)) {
      return {
        isValid: false,
        message:
          "Invalid username format. Must be at least 3 characters and contain no spaces.",
      };
    }

    return {isValid: true};
  };

  const handleUsernameSubmit = async () => {
    if (username.trim() && firstName && lastName && email) {
      const payload: Payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        user_name: username,
      };

      const validation = validatePayload(payload);

      if (!validation.isValid) {
        ReactGA.event({
          category: "User",
          action: "UpdateUserDetailsError",
          label: "Validation Error",
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: location.pathname + location.search,
          dimension2: localStorage.getItem("userId") || "",
          dimension3: validation.message,
        });
        toast.error(validation.message);
        return;
      }

      setLoading(true);
      try {
        ReactGA.event({
          category: "User",
          action: "UpdateUserDetails",
          label: location.pathname + location.search,
          value: 1,
          nonInteraction: true,
          // @ts-expect-error: Ignoring the type check for custom dimensions
          dimension1: localStorage.getItem("userId") || "",
          dimension2: JSON.stringify(payload),
        });
        const data = await updateUserData({user_info: payload});

        if (data.success) {
          // toast.success("User information updated successfully");
          localStorage.setItem("username", username);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          navigate(-1);
          setLoading(false);
        } else {
          ReactGA.event({
            category: "User",
            action: "UpdateUserDetailsError",
            label: "Api Error",
            value: 1,
            nonInteraction: true,
            // @ts-expect-error: Ignoring the type check for custom dimensions
            dimension1: location.pathname + location.search,
            dimension2: localStorage.getItem("userId") || "",
            dimension3: JSON.stringify(payload),
            dimension4: JSON.stringify(data),
          });
          // toast.error("Error updating user information");
          setLoading(false);
        }
      } catch (error: any) {
        toast.error(JSON.stringify(error));
        setLoading(false);
      }
    } else {
      toast.error("Please fill all fields.");
    }
  };

  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div className="user-details-centeredContainer">
          <div className="user-details-joinChatContainerss">
            <div className="user-details-joinChatHeading">
              Enter your details
            </div>
            <div className="user-details-joinChatSubHeading">
              This will be used for live chat
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "5%"}}>
              <div className="user-details-input-wrapper">
                <label className="user-details-input-label">First Name</label>
                <input
                  className="user-details-input"
                  style={{
                    width: "90%",
                    paddingLeft: "6%",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  type="text"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </div>
              <div className="user-details-input-wrapper">
                <label className="user-details-input-label">Last Name</label>
                <input
                  className="user-details-input"
                  style={{
                    width: "90%",
                    paddingLeft: "6%",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  type="text"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </div>
            </div>
            <div className="user-details-input-wrapper">
              <label className="user-details-input-label">Email</label>
              <input
                className="user-details-input"
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
            <div className="user-details-input-wrapper">
              <label className="user-details-input-label">Username</label>
              <input
                className="user-details-input"
                type="text"
                placeholder="Enter Username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div
              style={{
                // position: "fixed",
                bottom: 0,
                // width: "90%",
                marginTop: "46%",
                display: "flex",
                justifyContent: "center",
              }}>
              <button onClick={handleUsernameSubmit}>Next</button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserDetails;
