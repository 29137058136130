import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
}

const RupeeSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2130_8591)">
        <path
          d="M15.2921 8.70255H14.7531C14.6434 8.38647 14.4895 8.08751 14.2961 7.81455H15.2921C15.491 7.81455 15.6817 7.73553 15.8224 7.59488C15.963 7.45422 16.0421 7.26346 16.0421 7.06455C16.0421 6.86563 15.963 6.67487 15.8224 6.53422C15.6817 6.39356 15.491 6.31455 15.2921 6.31455H11.4796C11.4521 6.31455 11.4256 6.31055 11.3976 6.31055H9.41406C9.21515 6.31055 9.02439 6.38956 8.88373 6.53022C8.74308 6.67087 8.66406 6.86163 8.66406 7.06055C8.66406 7.25946 8.74308 7.45022 8.88373 7.59088C9.02439 7.73153 9.21515 7.81055 9.41406 7.81055H11.3976C11.7308 7.81056 12.059 7.8918 12.3537 8.04723C12.6484 8.20266 12.9008 8.42759 13.0891 8.70255H9.44506C9.24615 8.70255 9.05539 8.78156 8.91473 8.92222C8.77408 9.06287 8.69506 9.25364 8.69506 9.45255C8.69506 9.65146 8.77408 9.84222 8.91473 9.98288C9.05539 10.1235 9.24615 10.2025 9.44506 10.2025H13.4176C13.338 10.6812 13.0915 11.1162 12.7217 11.4303C12.3519 11.7445 11.8828 11.9174 11.3976 11.9185H9.41406C9.26745 11.9186 9.12408 11.9616 9.00166 12.0423C8.87924 12.123 8.78315 12.2378 8.72527 12.3725C8.66739 12.5072 8.65026 12.6559 8.67599 12.8002C8.70172 12.9445 8.7692 13.0782 8.87006 13.1845L12.9251 17.4595C12.9921 17.5337 13.0734 17.5936 13.164 17.6359C13.2545 17.6782 13.3527 17.7019 13.4526 17.7057C13.5525 17.7095 13.6521 17.6933 13.7457 17.6581C13.8392 17.6228 13.9248 17.5692 13.9973 17.5004C14.0698 17.4316 14.1279 17.349 14.168 17.2575C14.2081 17.1659 14.2296 17.0673 14.2311 16.9673C14.2325 16.8674 14.214 16.7681 14.1766 16.6754C14.1391 16.5827 14.0835 16.4984 14.0131 16.4275L11.1591 13.4185H11.3976C12.2813 13.4174 13.1329 13.0875 13.7868 12.4931C14.4407 11.8986 14.85 11.0822 14.9351 10.2025H15.2921C15.491 10.2025 15.6817 10.1235 15.8224 9.98288C15.963 9.84222 16.0421 9.65146 16.0421 9.45255C16.0421 9.25364 15.963 9.06287 15.8224 8.92222C15.6817 8.78156 15.491 8.70255 15.2921 8.70255Z"
          fill="#6B7280"
        />
        <path
          d="M12.4987 24C10.605 23.9998 8.73827 23.5514 7.05107 22.6915C5.36386 21.8317 3.90412 20.5847 2.79117 19.0526C1.67823 17.5204 0.943685 15.7467 0.647594 13.8763C0.351503 12.0059 0.502273 10.092 1.08758 8.29102C1.67288 6.49006 2.6761 4.85318 4.01522 3.51422C5.35433 2.17526 6.99132 1.17224 8.79235 0.587143C10.5934 0.00204963 12.5073 -0.148496 14.3777 0.147814C16.248 0.444124 18.0217 1.17888 19.5537 2.292C19.7146 2.40903 19.8223 2.58516 19.8533 2.78165C19.8843 2.97814 19.836 3.1789 19.719 3.33975C19.6019 3.50061 19.4258 3.60838 19.2293 3.63937C19.0328 3.67036 18.8321 3.62203 18.6712 3.505C16.5846 1.9909 14.0134 1.29853 11.4487 1.56013C8.88394 1.82173 6.50546 3.01897 4.76755 4.92316C3.02963 6.82735 2.0541 9.30503 2.02728 11.8829C2.00047 14.4608 2.92425 16.9583 4.62217 18.8982C6.3201 20.8381 8.67316 22.0846 11.2319 22.3995C13.7906 22.7144 16.3757 22.0756 18.4933 20.6053C20.6109 19.1349 22.1127 16.936 22.7117 14.4285C23.3107 11.921 22.9651 9.28075 21.7407 7.012C21.6532 6.83767 21.6371 6.6361 21.6957 6.45006C21.7544 6.26402 21.8832 6.10815 22.0549 6.01552C22.2265 5.92289 22.4275 5.90079 22.6152 5.9539C22.8029 6.00701 22.9625 6.13116 23.0602 6.3C24.0459 8.12828 24.5404 10.181 24.4952 12.2576C24.45 14.3342 23.8667 16.3635 22.8024 18.1472C21.7381 19.9309 20.2292 21.4078 18.4232 22.4337C16.6171 23.4596 14.5758 23.9993 12.4987 24Z"
          fill="#6B7280"
        />
        <path
          d="M21.0574 5.45005C20.9491 5.45019 20.8419 5.42685 20.7435 5.38162C20.645 5.33639 20.5575 5.27035 20.4869 5.18805L20.3519 5.03255C20.2851 4.95918 20.2334 4.87326 20.2 4.77978C20.1666 4.6863 20.1521 4.58711 20.1573 4.48797C20.1625 4.38883 20.1873 4.29172 20.2304 4.20225C20.2734 4.11279 20.3338 4.03276 20.408 3.9668C20.4822 3.90084 20.5687 3.85027 20.6626 3.81802C20.7565 3.78577 20.8559 3.77248 20.9549 3.77892C21.054 3.78537 21.1508 3.81141 21.2397 3.85555C21.3286 3.89969 21.4079 3.96104 21.4729 4.03605L21.6269 4.21255C21.7201 4.32147 21.7801 4.45481 21.7998 4.59678C21.8196 4.73874 21.7982 4.88339 21.7383 5.0136C21.6784 5.1438 21.5824 5.25411 21.4617 5.33146C21.3411 5.40881 21.2008 5.44997 21.0574 5.45005Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_2130_8591">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RupeeSvg;
