import React, {useEffect, useState} from "react";
import LoginMobile from "../../components/mobile/Login/LoginMobile/LoginMobile";
import OtpVerification from "../../components/mobile/Login/Otp/Otp";
import UserDetails from "../../components/mobile/Login/UserDetails/UserDetails";
import {useNavigate} from "react-router";
import ZoopLogo from "../../components/SvgImages/ZooLogo/ZoopLogo";
import {setAuthentication} from "../../features/Authentication/AuthenticationSlice";
import {useDispatch} from "react-redux";
import cookieUtils from "../../utils/cookies";
import {AppDispatch} from "../../interceptors/store";

const Login: React.FC = () => {
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(
    localStorage.getItem("isOtpVerified") === "true",
  );
  const [, setIsEditing] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(30);
  const [messageId, setMessageId] = useState<string>("");
  const [, setUserId] = useState<string>(localStorage.getItem("userId") || "");

  const dispatch = useDispatch<AppDispatch>(); // Specify AppDispatch type for dispatch
  const navigate = useNavigate();

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  }, [dispatch, navigate]);

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "7%",
        }}
        className="BrandHeading">
        <ZoopLogo height={52} width={114} />
      </div>
      {isOtpVerified ? (
        <UserDetails />
      ) : isOtpSent ? (
        <OtpVerification
          mobileNumber={mobileNumber}
          timeLeft={timeLeft}
          setIsOtpSent={setIsOtpSent}
          setIsOtpVerified={setIsOtpVerified}
          setIsEditing={setIsEditing}
          setUserId={setUserId}
          setMobileNumber={setMobileNumber}
          messageId={messageId}
          setMessageId={setMessageId}
        />
      ) : (
        <LoginMobile
          mobileNumber={mobileNumber}
          setIsOtpSent={setIsOtpSent}
          setIsEditing={setIsEditing}
          setMobileNumber={setMobileNumber}
          setTimeLeft={setTimeLeft}
          setMessageId={setMessageId}
        />
      )}
    </div>
  );
};

export default Login;
